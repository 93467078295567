<template>
  <div class="constrain logo">
    <Go :to="'/' + $store.state.router.locale" aria-label="Home">
      Logo
    </Go>
  </div>

  <div id="nav">
    <div class="relative constrain flex items-center text-sm justify-end">
      <nav class="inner flex">
        THIS IS THE NAVIGATION
      </nav>

      <Hamburger
        class="md:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />
    </div>
  </div>

  <div class="movile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <Go
          v-for="link of navigation?.links"
          :key="link.uri"
          :to="link.uri"
          class="px-4 link"
          :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
          @click="toggle(true)"
          v-text="link.title"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>

import Scrolllock from '@/hooks/scrolllock';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Language,
    Hamburger,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  width: 100%;

 a {
    display: flex;
    flex-direction: column;
  }

}

#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  padding-top: px(20);
  pointer-events: none;

  @media (max-width: theme("screens.md")) {
    padding-top: 20px;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link {
  font-weight: 500;
  &.active {
    text-decoration: underline;
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    padding: 0.8rem 1rem;
    text-decoration: none !important;
  }
}
</style>
