<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.data.slug"
      :key="component.data.id"
      :payload="component.data"
      class="fade-item"
    >
      <h2>Unable to load component «{{ component.component }}».</h2>
    </component>
    <Footer key="footer" class="fade-item" />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

    Seite: defineAsyncComponent(() => import('@/components/parts/Seite.vue')),
    TextWall: defineAsyncComponent(() => import('@/components/parts/TextWall.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
